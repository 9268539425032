import React from 'react';
import './app.css';
function App() {
  return (
    <React.Fragment>
      { renderHeader() }
      { renderSection1() }
      { renderDashedLine() }
      { renderSection2() }
      { renderDashedLine() }
      { renderSection3() }
      { renderDashedLine() }
      { renderSection4() }
      { renderFooter() }
    </React.Fragment>
  );
}

export default App;

const renderHeader = () => {
  return (
    <div className="relative w-full h-[600px] bg-gradient-to-b from-[#0037e3] to-[rgb(79,173,236)]">
        <div className="absolute bg-[url('./images/models.png')]  top-0 left-0 w-full h-full bg-[length:100%] bg-no-repeat bg-right-bottom">
        <div className="left-container pt-[80px] pl-[60px] z-10">
          <div className="flex flex-row items-center">
            <div className="logo w-[40px] h-[34px] bg-[url('./images/icon-noutline.png')] bg-no-repeat bg-cover"></div>
            <div className="text-white font-['DIN'] text-[22px] ml-[12px]">FileShare</div>
          </div>
          <h1 className="text-[40px] text-white font-['DIN'] w-1/2 mt-[120px]">Share Faster and Across Platforms</h1>
          <div className="flex flex-row items-center mt-[50px]">
            <a title="AppStore" href="https://apps.apple.com/us/app/fileshare-shareit-transfer/id6479360448">
              <div className="cursor-pointer w-[152px] h-[43px] bg-[url(./images/as-button.png)] bg-contain bg-no-repeat"></div>
            </a>
            <a title="GooglePlay" className="w-[152px] h-[43px] bg-[url(./images/gp-button.png)] bg-contain bg-no-repeat ml-[20px]" href="https://play.google.com/store/apps/details?id=pro.mindswitch.fileshareandroid"/>
          </div>
        </div>
        </div>
        <div className="wave absolute top-0 left-0 w-[290px] h-[260px] bg-[url('./images/wave.png')] bg-no-repeat bg-cover"></div>
        
      </div>
  );
}

const renderSection1 = () => {
  return (
    <div 
    className="w-full h-[530px] flex flex-row items-center justify-between pl-[100px] pr-[100px]">
      <div className="w-[444px] h-[410px] bg-[url('./images/feature-speed.png')] bg-no-repeat bg-contain"></div>
      <div className="font-['DIN'] pl-8">
        <h2 className="text-[40px] text-[#383838]">High Speed File Transfer</h2>
        <p className="text-[34px] text-[#bfbfbf] mt-[14px]">Super fast, 200 times faster than Bluetooth</p>
      </div>
      
    </div>
  );
}

const renderSection2 = () => {
  return (
    <div 
    className="w-full h-[530px] flex flex-row items-center justify-between pl-[100px] pr-[100px]">
      <div className="font-['DIN']">
        <h2 className="text-[40px] text-[#383838]">Universal File Sharing <br/>Cross Platform</h2>
        <p className="text-[34px] text-[#bfbfbf] mt-[14px]">Photos, video, contacts, music & file</p>
      </div>
      <div className="w-[500px] h-[420px] bg-[url('./images/feature-platform.png')] bg-no-repeat bg-contain"></div>
      
    </div>
  );
}

const renderSection3 = () => {
  return (
    <div 
    className="w-full h-[530px] flex flex-row items-center justify-center pl-[100px] pr-[100px]">
      <div className="w-[460px] h-[410px] bg-[url('./images/feature-click.png')] bg-no-repeat bg-contain pr-12"></div>
      <div className="font-['DIN'] pl-12">
        <h2 className="text-[40px] text-[#383838]">FILESHARE APP</h2>
        <p className="text-[34px] text-[#bfbfbf] mt-[14px]">Connect With One-Click</p>
      </div>
      
    </div>
  );
}

const renderSection4 = () => {
  return (
    <div 
    className="w-full h-[530px] flex flex-row items-center justify-center pl-[100px] pr-[100px]">
      <div className="w-[376px] h-[410px] bg-[url('./images/feature-secure.png')] bg-no-repeat bg-contain mr-12"></div>
      
      <div className="font-['DIN'] pl-12">
        <h2 className="text-[40px] text-[#383838]">Secure File Transfer</h2>
        <p className="text-[34px] text-[#bfbfbf] mt-[14px]">Protect your privacy with file encryption</p>
      </div>
      
    </div>
  );
}

const renderDashedLine = () => {
  return (
    <div className="w-full h-[20px] bg-white pl-[100px] pr-[100px]" >
      <div className='w-full h-1' style={{ borderBottom: '5px dotted #e1e1e1'}}></div>
    </div>
  );
}

const renderFooter = () => {
  return (
    <div className="w-full h-[140px] bg-[#1f6dff] flex flex-row items-center justify-center">
      <div className="flex flex-row font-['DIN'] items-center">
        <div className="w-[40px] h-[36px] bg-[url('./images/icon-noutline.png')] bg-no-repeat bg-contain"></div>
        <div>
          <p className="text-white text-[22px] ml-[12px]">FileShare</p>
          <p className="text-white text-[14px] ml-[12px]">2024 © MindSwitch LTD</p>
        </div>
      </div>
      <div style={{ width: '1px', background: 'rgba(255,255,255,0.8)'}} className="h-[44px] ml-[28px] mr-[28px]"></div>
      <div className="flex flex-col font-['DIN'] text-[12px] text-white justify-between">
        <p>71-75 Shelton Street Covent Garden</p>
        <p>LONDON, WC2H 9JQ</p>
        <p>United Kingdom</p>
      </div>
      <div style={{ width: '1px', background: 'rgba(255,255,255,0.8)'}} className="h-[44px] ml-[28px] mr-[28px]"></div>
      <div className="flex flex-col font-['DIN'] text-[12px] min-h-[44px] text-white justify-between">
        <a href="https://terms.mindswitch.pro/fileshare/privacy">Privacy Policy</a>
        <a href="https://terms.mindswitch.pro/fileshare/terms">Terms of Use</a>
      </div>

    </div>
  );
}